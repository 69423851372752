<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <!-- <span class="remarksAlink">湖北省药品监督管理局：<a target="_blank" href="http://fda.hubei.gov.cn/">http://fda.hubei.gov.cn/</a> 操作简要：点击进入网站-找到电子证书-在企业名称栏输入公司名称-查询，即可找到该公司的经营许可证/经营备案证书。</span> -->
      <span class="remarksAlink">国家药品监督管理局官网：<a target="_blank" href="https://www.nmpa.gov.cn/">https://www.nmpa.gov.cn/</a> 操作简要：点击进入网站-点击“医疗器械”-左侧国产器械（注册证号带有“准”）或进口器械（注册证号带有“进”），在快速查询里面输入需要查询的生产许可证/备案证号，然后打开生产许可证/备案证信息复制粘贴即可完成信息维护。 </span>
      <div class="formLine">
        <FromLabel :fontColor="changeList.includes('productionlicense_file_name')" label="上传生产许可证" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Input readonly :disabled="isBand" v-model="form.productionlicense_file_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload :isSupplier="true" :disabled="isBand" label="上传" @get-key="getKey" :data="data"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg nowrap" @click="seeLicence">{{ form.productionlicense_file_name }}</span> -->
          <span class="lookupImg nowrap" v-if="form.productionlicense_key && form.factory_name" @click="seeLicence">{{ form.factory_name }}-生产许可证</span>
          <span class="lookupImg nowrap" v-else-if="form.productionlicense_key && !form.factory_name" @click="seeLicence">-生产许可证</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('effective_start_date') || changeList.includes('effective_end_date')" label="有效期至" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <DatePicker :disabled="isBand" @on-change="changeTime($event, 1)" clearable :value="form.effective_start_date" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <span class="marginWords10"> 至 </span>
          <DatePicker :disabled="isBand" @on-change="changeTime($event, 2)" clearable :value="form.effective_end_date" :options="options" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
        </div>
        <FromLabel :fontColor="changeList.includes('license_number')" label="许可证编号" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input :disabled="isBand" v-model="form.license_number" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('product_factory_name')" label="企业名称" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input :disabled="isBand || Boolean(is_approved)" v-model="form.product_factory_name" placeholder="请输入" class="iviewIptWidth307" @on-change="factoryNameChange"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('product_legal_person')" label="法人" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input :disabled="isBand" v-model="form.product_legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="企业负责人" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input :disabled="isBand" v-model="form.product_responsible_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('product_production_address')" label="生产地址" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Input :disabled="isBand" v-model="form.product_production_address" class="iviewIptWidth1075px" placeholder="请输入..."></Input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <!-- <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span :class="{ redColor: changeList.includes('product_residence') }">住所</span>
          </div>
        </div> -->
        <FromLabel :fontColor="changeList.includes('product_residence')" label="住所" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand" type="text" maxlength="200" show-word-limit v-model="form.product_residence" placeholder="请输入住所" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight150">
        <div class="newformLabel fl moreHeight150">
          <div>
            <span class="icon">*</span>
            <span :class="{ redColor: changeList.includes('product_production_range') }">生产范围</span>
          </div>
        </div>
        <!-- <FromLabel :fontColor="changeList.includes('product_production_range')" label="生产范围" :showIcon="true"></FromLabel> -->
        <div class="allWidth">
          <i-input :disabled="isBand" type="textarea" maxlength="500" show-word-limit :autosize="{ minRows: 3, maxRows: 3 }"  v-model="form.product_production_range" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('certification_department')" label="发证部门"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input :disabled="isBand" v-model="form.certification_department" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="发证日期" :fontColor="changeList.includes('certification_date')"></FromLabel>
        <div class="formFlIpt647 fl">
          <DatePicker
            :disabled="isBand"
            @on-change="
              e => {
                this.form.certification_date = e
              }
            "
            :value="form.certification_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '../../../components/formUpload'
import http from '../../../service/baseUrl'
export default {
  name: 'productLicense',
  components: {
    FromLabel,
    FormUpload,
  },
  data() {
    return {
      changeList: [],
      options: {},
      type: '',
      license: [],
      isLoad: false,
      typeList: [1],
      isBand: false,
      // 获取数据
      form: {},
      // 判断tab选择
      data_maintenanc: '',
      data: {},
    }
  },
  props: {
    factoryName: String,
    is_approved: Number,
  },
  mounted() {
    if (this.$route.query.id) {
      this.data = {
        factory_id: this.$route.query.id,
        table_type: 3,
      }
    } else {
      this.data = {
        table_type: 3,
      }
    }
    // 这里获取了 厂家id
    this.judeIsband()
  },
  methods: {
    // 厂家名称改变
    factoryNameChange(e) {
      this.$root.$emit('changeFactoryName', e.target.value)
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.form.effective_start_date = e
        that.form.effective_end_date = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.form.effective_start_date)
          },
        }
      } else {
        this.form.effective_end_date = e
      }
    },
    // 点击名字凭证
    seeLicence() {
      // window.open(this.form.productionlicense_file_url)
      window.open(this.form.productionlicense_url)
    },
    // 状态是1 就是点名字进入; 状态是0 就是 点击审核进入
    judeIsband() {
      if (this.$route.query.status == '1') {
        this.isBand = true
      }
      if (this.$route.query.status == '0') {
        this.isBand = false
      }
    },
    // 上传按钮触发事件
    getKey(key, name, url) {
      this.form.productionlicense_key = key
      this.form.productionlicense_file_name = this.form.product_factory_name
      // this.form.productionlicense_file_url = url
      this.form.productionlicense_url = url
    },
  },
  watch: {
    factoryName: function (newVal, oldVal) {
      let that = this
      let name = newVal || oldVal
      this.form.factory_name = name
      this.form.product_factory_name = name
      if (that.form.productionlicense_key) {
        that.form.productionlicense_file_name = name + '-生产许可证'
      }
    },
  },
}
</script>

<style scoped lang="less">
.redColor {
  color: #f73333 !important;
}
.moreHeight {
  height: 100px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
.iviewIptWidth1075px {
  width: 1140px;
}
.fontSize14px {
  font-size: 14px;
}

.content {
  height: 100%;
  .fromDiv {
    position: relative;
    height: 100%;
    .saveDiv {
      position: absolute;
      right: 23px;
      bottom: 5px;
    }
  }
}

.content {
  padding: 0px 30px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.iviewIptWidth170 {
  width: 170px;
}
.moreHeight150{
  height: 150px;
}
</style>
