<template>
  <div class="content">
    <div class="box">
      <!-- 采购部审核意见 -->
      <div class="tableTitle" style="margin-top: -10px;">
        <span class="title"> 采购部审核意见 </span>
      </div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input :disabled="judg1" v-model="form.purchasing_opinion" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input :disabled="judg1" v-model="form.purchasing_person" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup @on-change="change1" v-model="form.purchasingn_state">
              <Radio :disabled="judg1" class="marginRight20" label="1">同意</Radio>
              <Radio :disabled="judg1" label="0">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :disabled="judg1" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.purchasing_date" @on-open-change="openChange($event, 'purchasing_date')" @on-change="changeTime($event, 'purchasing_date')"></DatePicker>
          </div>
        </div>
      </div>
      <!-- 质量管理部门审核意见 -->
      <div class="tableTitle">
        <span class="title"> 质量管理部门审核意见 </span>
      </div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input :disabled="judg2" v-model="form.quality_opinion" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input :disabled="judg2" v-model="form.qualityn_person" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup v-model="form.quality_state">
              <Radio :disabled="judg2" class="marginRight20" label="1">同意</Radio>
              <Radio :disabled="judg2" label="0">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :disabled="judg2" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.quality_date" @on-open-change="openChange($event, 'quality_date')" @on-change="changeTime($event, 'quality_date')"></DatePicker>
          </div>
        </div>
      </div>

      <!-- 财务部审核意见 -->
      <div class="tableTitle">
        <span class="title"> 财务部审核意见 </span>
      </div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input :disabled="judg3" v-model="form.finance_opinion" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input :disabled="judg3" v-model="form.finance_person" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup v-model="form.finance_state">
              <Radio :disabled="judg3" class="marginRight20" label="1">同意</Radio>
              <Radio :disabled="judg3" label="0">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :disabled="judg3" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.finance_date" @on-open-change="openChange($event, 'finance_date')" @on-change="changeTime($event, 'finance_date')"></DatePicker>
          </div>
        </div>
      </div>

      <!-- 总经理或管理者代表意见 -->
      <div class="tableTitle">
        <span class="title"> 总经理意见 </span>
      </div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input :disabled="judg4" v-model="form.admin_opinion" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input :disabled="judg4" v-model="form.admin_person" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup v-model="form.admin_state">
              <Radio :disabled="judg4" class="marginRight20" label="1">同意</Radio>
              <Radio :disabled="judg4" label="0">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :disabled="judg4" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.admin_date" @on-open-change="openChange($event, 'admin_date')" @on-change="changeTime($event, 'admin_date')"></DatePicker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
export default {
  name: 'businessApproval',
  components: {
    FromLabel,
  },
  data() {
    return {
      changeList: [],
      type: '',
      isLoad: false,
      typeList: [1],
      factory_approval: null,
      form: {
        factory_id: this.$route.query.id || null, // 工厂ID
        factory_approval: this.factory_approval || null,
        purchasing_opinion: null, // 财务部审核意见
        purchasing_person: null, // 财务部负责人签名
        purchasingn_state: null, //	财务部状态0=驳回 1=同意
        purchasing_date: null, // 财务部审核日期
        quality_opinion: null, //	质量部审核意见
        qualityn_person: null, //	质量部负责人签名
        quality_state: null, // 质量部盛和状态0=驳回 1=同意
        quality_date: null, //	质量部审核日期
        finance_opinion: null, // 财务部审核意见
        finance_person: null, //	财务部负责人签字
        finance_state: null, // 财务部审核状态0=驳回 1=同意
        finance_date: null, // 财务部审核日期
        admin_opinion: null, //	管理者审核意见
        admin_person: null, // 管理部负责人签字
        admin_state: null, // 管理部审核状态0=驳回 1=同意
        admin_date: null, //	管理部审核日期
      },
      data_maintenanc: '',
      // 从本地获取登陆的角色id
      RoleId: '',
      judg1: false,
      judg2: false,
      judg3: false,
      judg4: false,
    }
  },
  mounted() {
    this.RoleId = window.localStorage.getItem('userRole')
    // this.getApproval()
    this.judeIsband()
  },
  methods: {
    openChange(e, name) {
      if (!this.form[name]) {
        this.form[name] = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      }
    },
    // 时间改变
    changeTime(e, name) {
      console.log(e, 'e')
      this.form[name] = e
    },
    judeIsband() {
      // 获取用户登陆的角色身份
      // console.log(this.RoleId)
      // 点击名称进入不让看
      if (this.$route.query.status == '1') {
        this.judg1 = true
        this.judg2 = true
        this.judg3 = true
        this.judg4 = true
      }
      // 点击审核进入 根绝角色身份判定是否查看
      if (this.$route.query.status == '0') {
        console.log(this.RoleId, '!!!!!!!!!!!!!!!!')
        // 如果为超级管理员  角色为1 全都可以编辑
        if (this.RoleId == '1') {
          this.judg1 = false
          this.judg2 = false
          this.judg3 = false
          this.judg4 = false
        }

        // 如果为总经理  角色为2 全都可以编辑
        else if (this.RoleId == '2') {
          this.judg1 = false
          this.judg2 = false
          this.judg3 = false
          this.judg4 = false
        }

        // 如果为采购部
        else if (this.RoleId == '3' || this.RoleId == '4') {
          this.judg1 = false
          this.judg2 = true
          this.judg3 = true
          this.judg4 = true
        }

        // 如果为质量管理部
        else if (this.RoleId == '8' || this.RoleId == '15') {
          this.judg1 = true
          this.judg2 = false
          this.judg3 = true
          this.judg4 = true
        }

        // 如果为财务部
        else if (this.RoleId == '10' || this.RoleId == '11') {
          this.judg1 = true
          this.judg2 = true
          this.judg3 = false
          this.judg4 = true
        }

        // 如果是别的角色
        else {
          this.judg1 = true
          this.judg2 = true
          this.judg3 = true
          this.judg4 = true
        }
      }
    },
    change1(e) {
      console.log(e)
    },
    changePage(e) {},
  },
}
</script>

<style scoped lang="less">
.fontSize14px {
  font-size: 14px;
}
.saveDiv {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 23px;
}
.content {
  padding: 0px 30px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 10px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}
.marginRight20 {
  margin-right: 20px;
}
/deep/ .fullInput {
  width: 100%;
  height: 85px;
  padding: 7px;
  box-sizing: border-box;
  border: 1px solid #e8eaec;
  .ivu-input-wrapper {
    height: 100% !important;
  }
  textarea.ivu-input {
    height: 100% !important;
  }
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
</style>
