<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <!-- <span class="remarksAlink">湖北省药品监督管理局：<a target="_blank" href="http://fda.hubei.gov.cn/">http://fda.hubei.gov.cn/</a> 操作简要：点击进入网站-找到电子证书-在企业名称栏输入公司名称-查询，即可找到该公司的经营许可证/经营备案证书。</span> -->
      <span class="remarksAlink">国家药品监督管理局官网：<a target="_blank" href="https://www.nmpa.gov.cn/">https://www.nmpa.gov.cn/</a> 操作简要：点击进入网站-点击“医疗器械”-左侧国产器械（注册证号带有“准”）或进口器械（注册证号带有“进”），在快速查询里面输入需要查询的生产许可证/备案证号，然后打开生产许可证/备案证信息复制粘贴即可完成信息维护。 </span>
      <div class="formLine">
        <FromLabel :fontColor="changeList.includes('filing_file_name')" label="备案凭证" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Input readonly :disabled="isBand" v-model="form.filing_file_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload :isSupplier="true" :disabled="isBand" label="上传" @get-key="getKey" :data="data"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg nowrap" @click="seeLicence">{{ form.filing_file_name }}</span> -->
          <span class="lookupImg nowrap" v-if="form.filing_certificate_key && form.filing_factory_name" @click="seeLicence">{{ form.filing_factory_name }}-备案凭证</span>
          <span class="lookupImg nowrap" v-else-if="form.filing_certificate_key && !form.filing_factory_name" @click="seeLicence">-备案凭证</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('filing_number')" label="备案号" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand" v-model="form.filing_number" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('filing_factory_name')" label="企业名称" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand || Boolean(is_approved)" v-model="form.filing_factory_name" placeholder="请输入" class="iviewIptWidth307" @on-change="factoryNameChange"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('filing_legal_person')" label="法人" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input :disabled="isBand" v-model="form.filing_legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel :fontColor="changeList.includes('filing_responsible_person')" label="企业负责人" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <Input :disabled="isBand" v-model="form.filing_responsible_person" class="iviewIptWidth307" placeholder="请输入..."></Input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <!-- <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span :class="{ redColor: changeList.includes('filing_residence') }">住所</span>
          </div>
        </div> -->
        <FromLabel :fontColor="changeList.includes('filing_residence')" label="住所" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand" type="text" maxlength="200" show-word-limit  v-model="form.filing_residence" placeholder="请输入住所" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('filing_production_address')" label="生产地址" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <Input :disabled="isBand" v-model="form.filing_production_address" class="iviewIptWidth1075px" placeholder="请输入..."></Input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight150">
        <div class="newformLabel fl moreHeight150">
          <div>
            <span class="icon">*</span>
            <span :class="{ redColor: changeList.includes('filing_production_range') }">生产范围</span>
          </div>
        </div>
        <!-- <FromLabel :fontColor="changeList.includes('filing_production_range')" label="生产范围" :showIcon="true"></FromLabel> -->
        <div class="allWidth">
          <i-input :disabled="isBand" type="textarea" maxlength="500" show-word-limit :autosize="{ minRows: 3, maxRows: 3 }" v-model="form.filing_production_range" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('filing_department')" label="备案部门"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input :disabled="isBand" v-model="form.filing_department" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel :fontColor="changeList.includes('filing_date')" label="备案日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker
            :disabled="isBand"
            @on-change="
              e => {
                this.form.filing_date = e
              }
            "
            :value="form.filing_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
      </div>

      <!-- <div class="saveDiv clearfix">
        <Button v-show="!isBand" class="marginleft10px" type="primary" @click="nextstep()"><span class="fontSize14px">保存</span></Button>
      </div> -->
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '../../../components/formUpload'
import http from '../../../service/baseUrl'
export default {
  name: 'registrationCertificate',
  components: {
    FromLabel,
    FormUpload,
  },
  data() {
    return {
      changeList: [],
      type: '',
      license: [],
      isLoad: false,
      imgList: [{ src: '' }, { src: '' }],
      typeList: [1],
      // 获取数据
      form: {},
      isBand: false,
      data: {},
    }
  },
  props: ['factory_filing', 'factoryName', 'is_approved'],
  created() {
    this.url = http.http + '/public/upload/file'
    this.header = {
      Authorization: 'Bearer ' + sessionStorage.getItem('adminohoqicbi=='),
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.data = {
        factory_id: this.$route.query.id,
        table_type: 4,
      }
    } else {
      this.data = {
        table_type: 4,
      }
    }
    // this.getfilingcertificates()
    this.judeIsband()
  },
  methods: {
    // 厂家名称改变
    factoryNameChange(e) {
      this.$root.$emit('changeFactoryName', e.target.value)
    },
    seeLicence() {
      window.open(this.form.filing_certificate_url)
    },
    judeIsband() {
      if (this.$route.query.status == '1') {
        this.isBand = true
      }
      if (this.$route.query.status == '0') {
        this.isBand = false
      }
    },
    // 上传函数
    getKey(key, name, url) {
      this.form.filing_certificate_key = key
      this.form.filing_file_name = this.form.filing_factory_name
      this.form.filing_certificate_url = url
    },
    changePage(e) {},
    // 上一步
    backstep() {
      console.log('返回')
      this.data_maintenanc = localStorage.getItem('data_maintenance')
      if (this.data_maintenanc == '3') {
        this.$emit('sendform', 2)
      }
      if (this.data_maintenanc == '2') {
        this.$emit('sendform', 1)
      }
    },
    // 下一步
    nextstep() {
      let data = {
        factory_filing: this.factory_filing,
        factory_id: this.$route.query.id, // 工厂ID
        filing_file_name: this.form.filing_file_name,
        filing_certificate_key: this.form.filing_certificate_key, // 备案凭证KEY
        filing_number: this.form.filing_number, //	备案号
        filing_factory_name: this.form.filing_factory_name, // 工厂姓名
        filing_production_address: this.form.filing_production_address, //	生产地址
        filing_residence: this.form.filing_residence, // 住所
        filing_legal_person: this.form.filing_legal_person, //	法人
        filing_responsible_person: this.form.filing_responsible_person, // 企业负责人
        filing_production_range: this.form.filing_production_range, // 生产范围
        filing_department: this.form.filing_department, // 备案部门
        filing_date: this.form.filing_date, // 备案日期
      }

      if (!data.filing_number) {
        this.$Message.warning('请填写备案号')
        return
      }
      if (!data.filing_factory_name) {
        this.$Message.warning('请填写企业名称')
        return
      }
      if (!data.filing_legal_person) {
        this.$Message.warning('请填写法人')
        return
      }
      if (!data.filing_residence) {
        this.$Message.warning('请填写住所')
        return
      }
      if (!data.filing_production_address) {
        this.$Message.warning('请填写生产地址')
        return
      }
      if (!data.filing_production_range) {
        this.$Message.warning('请填写生产范围')
        return
      }
      if (!data.filing_date) {
        this.$Message.warning('请填写备案日期')
        return
      }
      console.log(data)

      this.$http.post(this.$api.factoryManageFilingcertificate, data, true).then(res => {
        console.log(res)
        this.$Message.success('保存成功')
        localStorage.setItem('roleIdOfApproval', res.role_id)
        // 获取本地存储数据
        this.data_maintenanc = localStorage.getItem('data_maintenance')
        if (this.data_maintenanc == '3' || this.data_maintenanc == '2') {
          this.$emit('sendform', 4)
        }
        if (this.data_maintenanc == '1') {
          this.$Message.warning('资料维护信息错误')
        }
      })
      // console.log('添加成功')
    },
    // 获取备案凭证列表
    // getfilingcertificates() {
    //   if (this.factory_filing != null) {
    //     var data = {
    //       factory_id: this.$route.query.id,
    //       factory_filing: this.factory_filing,
    //     }
    //     this.$http.get(this.$api.factoryManageFilingcertificate, data, true).then(res => {
    //       console.log(res)
    //       this.form = res.data
    //       this.form.filing_date = this.$moment.unix(this.form.filing_date).format('YYYY-MM-DD')
    //     })
    //   }
    // },
  },
  watch: {
    factoryName: function (newVal, oldVal) {
      let that = this
      let name = newVal || oldVal
      that.form.filing_factory_name = name
      if (that.form.filing_certificate_key) {
        that.form.filing_file_name = name + '-备案凭证'
      }
    },
  },
}
</script>

<style scoped lang="less">
.moreHeight {
  height: 100px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
.fontSize14px {
  font-size: 14px;
}

.iviewIptWidth1075px {
  width: 1140px;
}

.content {
  height: 100%;
  .fromDiv {
    position: relative;
    height: 100%;
    .saveDiv {
      position: absolute;
      right: 23px;
      bottom: 5px;
    }
  }
}

.content {
  padding: 0px 30px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.moreHeight150{
  height: 150px;
}
</style>
