<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel :fontColor="changeList.includes('businesslicense_file_name')" label="营业执照" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <Input readonly :disabled="isBand" v-model="form.businesslicense_file_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload :isSupplier="true" :disabled="isBand" label="上传" @get-key="getKey" :data="data1"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg nowrap" @click="seeLicence">{{ form.businesslicense_file_name }}</span> -->
          <span class="lookupImg nowrap" v-if="form.businesslicense_key && form.factory_name" @click="seeLicence">{{ form.factory_name }}-营业执照</span>
          <span class="lookupImg nowrap" v-else-if="!form.factory_name && form.businesslicense_key" @click="seeLicence">-营业执照</span>
        </div>
        <FromLabel label="上传合同" :fontColor="changeList.includes('contract_file_name')"></FromLabel>
        <div class="formFrIpt fl">
          <Input readonly :disabled="isBand" v-model="form.contract_file_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload :isSupplier="true" :disabled="isBand" label="上传" @get-key="getKey2" :data="data2"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg nowrap" @click="seeLicence2">{{ form.contract_file_name }}</span> -->
          <span class="lookupImg nowrap" v-if="form.contract_key && form.factory_name" @click="seeLicence2">{{ form.contract_file_name }}</span>
          <span class="lookupImg nowrap" v-else-if="form.contract_key && !form.factory_name" @click="seeLicence2">-合同</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="公司名称" :fontColor="changeList.includes('factory_name')" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input :disabled="isBand || Boolean(is_approved)" v-model="form.factory_name" placeholder="请输入" class="iviewIptWidth307" @on-change="factoryNameChange"></i-input>
        </div>
        <FromLabel label="统一社会信用代码" :fontColor="changeList.includes('social_credit_code')" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input :disabled="isBand" v-model="form.social_credit_code" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="类型"></FromLabel>
        <div class="allWidth">
          <Select :disabled="isBand" v-model="form.factory_type" class="iviewIptWidth165">
            <Option v-for="(item, index) in typeList" :key="index" :value="item.id">{{ item.type }}</Option>
          </Select>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <!-- <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span :class="{ redColor: changeList.includes('detailed_address') }">住所</span>
          </div>
        </div> -->
        <FromLabel label="住所" :fontColor="changeList.includes('product') || changeList.includes('fill')" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand" type="text" maxlength="200" show-word-limit  v-model="form.detailed_address" placeholder="请输入住所" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="法人" :fontColor="changeList.includes('legal_person')" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand" v-model="form.legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('setup_date')" label="成立日期"></FromLabel>
        <div class="formFlIpt647 fl">
          <DatePicker
            :disabled="isBand"
            @on-change="
              e => {
                this.form.setup_date = e
              }
            "
            :value="form.setup_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
        <FromLabel :fontColor="changeList.includes('registered_capital')" label="注册资本" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input :min="0" :disabled="isBand" v-model="form.registered_capital" placeholder="请输入" class="iviewIptWidth307"></i-input>
          <span class="afterWords10"> 万元</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :fontColor="changeList.includes('business_start') || changeList.includes('business_end')" label="营业期限"></FromLabel>
        <div class="formFlIpt647 fl">
          <DatePicker :options="options2" @on-change="changeTime($event, 1)" :disabled="isBand" :value="form.business_start" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <span class="marginWords10"> 至 </span>
          <DatePicker @on-change="changeTime($event, 2)" :disabled="isBand" :options="options" :value="form.business_end" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <Checkbox :disabled="isBand" true-value="1" false-value="0" v-model="form.is_long_term" class="singleCheckBox">长期</Checkbox>
        </div>
        <FromLabel label="登记日期" :fontColor="changeList.includes('registered_date')"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker
            :disabled="isBand"
            @on-change="
              e => {
                this.form.registered_date = e
              }
            "
            :value="form.registered_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight150">
        <div class="newformLabel fl newformLabelHeight">
          <div>
            <span class="icon">*</span>
            <span :class="{ redColor: changeList.includes('business_range') }">经营范围</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input :disabled="isBand" type="textarea" maxlength="500" show-word-limit :autosize="{ minRows: 3, maxRows: 3 }" v-model="form.business_range" placeholder="请输入经营范围" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="资料维护" :fontColor="changeList.includes('product') || changeList.includes('fill')" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Checkbox :disabled="isBand" true-value="1" false-value="0" v-model="form.product" class="singleCheckBox">生产许可证</Checkbox>
          <Checkbox :disabled="isBand" true-value="1" false-value="0" v-model="form.fill" class="singleCheckBox">备案凭证</Checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import http from '../../../service/baseUrl'
import FormUpload from '../../../components/formUpload'
export default {
  name: 'businessLicenseInfo',
  components: {
    FromLabel,
    FormUpload,
  },
  data() {
    return {
      data1: {},
      data2: {},
      changeList: [],
      longTerm: false,
      options: {},
      options2: {},
      isBand: false,
      license: [],
      isLoad: false,
      imgStatus1: '',
      imgStatus2: '',
      typeList: [
        { id: '1', type: '国内厂家' },
        { id: '2', type: '境外厂家' },
      ],
      // 公司省份
      companyProvince: '',
      // 公司省份列表
      companyprovinceList: [],
      // 公司市区
      companyCity: '',
      // 公司市区列表
      companyCityList: [],
      // 控制是否是供应商
      issuply: false,
      form: {},
      contract: '',
      // 工厂id
      factory_id: '',
    }
  },
  props: {
    factoryName: String,
    is_approved: Number,
  },
  //
  created() {
  },
  mounted() {
    if (this.$route.query.id) {
      this.data1 = {
        factory_id: this.$route.query.id,
        table_type: 1,
      }
      this.data2 = {
        factory_id: this.$route.query.id,
        table_type: 2,
      }
    } else {
      this.data1 = {
        table_type: 1,
      }
      this.data2 = {
        table_type: 2,
      }
    }
    // 获取数据
    this.judeIsband()
  },
  methods: {
    // 厂家名称改变
    factoryNameChange(e) {
      this.$root.$emit('changeFactoryName', e.target.value)
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.form.business_start = e
        that.form.business_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.form.business_start)
          },
        }
      } else {
        this.form.business_end = e
      }
    },
    seeLicence() {
      window.open(this.form.businesslicense_url)
    },
    seeLicence2() {
      window.open(this.form.contract_url)
    },
    // 状态为1 点击名称 进入; 状态0 点击审核进入
    judeIsband() {
      if (this.$route.query.status == '1') {
        this.isBand = true
      }
      if (this.$route.query.status == '0') {
        this.isBand = false
      }
    },
    // 上传按钮触发事件
    getKey(key, name, url) {
      this.form.businesslicense_key = key
      this.form.businesslicense_file_name = this.form.factory_name
      this.form.businesslicense_url = url
    },

    getKey2(key, name, url) {
      this.form.contract_key = key
      this.form.contract_file_name = this.form.factory_name
      this.form.contract_file_url = url
    },
    // 点击长期触发实现
    // LongTermChange(e) {
    //   this.longTerm = !this.longTerm
    //   this.form.business_end = ''
    // },

    // 点击保存触发事件
    // nextstep() {
    //   // 如果生产许可和备案凭证 都选择了  data_maintenance为3
    //   if (this.form.product == '1' && this.form.fill == '1') {
    //     this.form.data_maintenance = '3'
    //   }
    //   // 如果只选择了备案凭证 data_maintenance为2
    //   if (this.form.product == '0' && this.form.fill == '1') {
    //     this.form.data_maintenance = '2'
    //   }
    //   // 如果只选择了生产许可 data_maintenance为1
    //   if (this.form.product == '1' && this.form.fill == '0') {
    //     this.form.data_maintenance = '1'
    //   }
    //   // 如果都没选择就为空
    //   if (this.form.product == '0' && this.form.fill == '0') {
    //     this.form.data_maintenance = ''
    //   }

    //   // 两个都选了
    //   let postform = {
    //     factory_id: this.$route.query.id,
    //     factory_name: this.form.factory_name, //	工厂姓名
    //     businesslicense_file_name: this.form.businesslicense_file_name,
    //     businesslicense_file_url: this.businesslicense_file_url,
    //     contract_file_name: this.form.contract_file_name,
    //     businesslicense_key: this.form.businesslicense_key, // 营业执照Key
    //     contract_key: this.form.contract_key, // 合同KEY
    //     social_credit_code: this.form.social_credit_code, // 统一社会信用代码
    //     is_supplier: this.form.is_supplier, // 	是否为供应商 1=是 0=否
    //     is_long_term: this.form.is_long_term, // 是否长期 1是 0否
    //     province: this.form.province, // 地址ID
    //     city: this.form.city, // 地址ID
    //     detailed_address: this.form.detailed_address, // 详情地址
    //     legal_person: this.form.legal_person, // 法人
    //     setup_date: this.form.setup_date, // 成立日期
    //     registered_date: this.form.registered_date, // 登记日期
    //     registered_capital: this.form.registered_capital, // 注册资本
    //     business_start: this.form.business_start, // 起始营业期限
    //     business_end: this.form.business_end, // 截止营业期限
    //     business_range: this.form.business_range, // 经营范围
    //     fill: this.form.fill,
    //     product: this.form.product,
    //     factory_type: this.form.factory_type, // 厂家类型 1=国内厂家 2=境外厂家 0=未知
    //     data_maintenance: this.form.data_maintenance, // 维护状态
    //   }

    //   // ------------对象 提交前 添加判定条件----------
    //   // 时间检验
    //   if (postform.business_start == '') {
    //     delete postform.business_start
    //   }
    //   if (postform.business_end == '') {
    //     delete postform.business_end
    //   }
    //   if (postform.setup_date == '') {
    //     delete postform.setup_date
    //   }
    //   if (postform.registered_date == '') {
    //     delete postform.registered_date
    //   }

    //   // -----------------------验证规则
    //   let data = postform
    //   // 如果长期
    //   if (this.longTerm) {
    //     this.form.is_long_term = '1'
    //     if (this.form.business_start == '') {
    //       this.$Message.warning('请填写起始营业期限')
    //       return
    //     }
    //   }
    //   // 如果不长期
    //   if (!this.longTerm) {
    //     this.form.is_long_term = '0'
    //     if (this.form.business_start == '') {
    //       this.$Message.warning('请填写起始营业期限')
    //       return
    //     }
    //     if (this.form.business_end == '') {
    //       this.$Message.warning('请填写截止营业期限')
    //       return
    //     }
    //   }
    //   if (!data.factory_name) {
    //     this.$Message.warning('请填写公司名称')
    //     return
    //   }
    //   if (!data.social_credit_code) {
    //     this.$Message.warning('请填写统一社会信用代码')
    //     return
    //   }
    //   if (!data.legal_person) {
    //     this.$Message.warning('请填法人')
    //     return
    //   }
    //   if (!data.registered_capital) {
    //     this.$Message.warning('请填写注册资本')
    //     return
    //   }
    //   if (!data.business_range) {
    //     this.$Message.warning('请填写经营范围')
    //     return
    //   }
    //   if (data.data_maintenance == '') {
    //     this.$Message.warning('请填写资料维护')
    //     return
    //   }
    //   // -----------------------验证规则

    //   // 点击下一步
    //   this.$http
    //     .post(this.$api.factoryManage, data, true)
    //     .then(res => {
    //       if (data.data_maintenance == '3' || data.data_maintenance == '1') {
    //         localStorage.setItem('data_maintenance', data.data_maintenance)
    //         this.$emit('sendform', 2)
    //       }
    //       if (data.data_maintenance == '2') {
    //         localStorage.setItem('data_maintenance', data.data_maintenance)
    //         this.$emit('sendform', 3)
    //       }
    //     })
    // },
  },
  watch: {
    factoryName: function (newVal, oldVal) {
      let that = this
      let name = newVal || oldVal
      that.form.factory_name = name
      console.log(name, that.form.factory_name )
      if (that.form.businesslicense_key) {
        that.form.businesslicense_file_name = name + '-营业执照'
      }
      if (that.form.contract_key) {
        that.form.contract_file_name = name + '-合同'
      }
    },
  },
}
</script>

<style scoped lang="less">
.redColor {
  color: #f73333 !important;
}
.moreHeight {
  height: 100px;
}
.moreHeight150 {
  height: 150px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
.newformLabelHeight{
  height: 150px;
}
.fontSize14px {
  font-size: 14px;
}

.iviewIptWidth1075px {
  width: 1150px;
}

.content {
  height: 100%;
  .fromDiv {
    position: relative;
    height: 100%;
    .saveDiv {
      position: absolute;
      right: 23px;
      bottom: 5px;
    }
  }
}

.iviewIptWidth170 {
  width: 170px;
}
.content {
  padding: 0px 30px;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.iviewidth775px {
  width: 775px;
}
</style>
