<template>
  <div class="page">
    <ul class="topNavDiv">
      <!-- v-show="this.data_maintenance=='1'" -->
      <li v-show="item.status" v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="btmDiv">
      <div class="tabelDiv">
        <BusinessLicenseInfo ref="businessLicenseInfo" :factoryName="factoryName" :is_approved="is_approved" @sendfactory_productlicense="getfactory_productlicense($event)" @sendfactory_filing="getfactory_filing($event)" v-show="clickIndex == 1"></BusinessLicenseInfo>
        <ProductLicense ref="productLicense" :factoryName="factoryName" :is_approved="is_approved" :factory_productlicense="factory_productlicense" v-show="clickIndex == 2"></ProductLicense>
        <RegistrationCertificate ref="registrationCertificate" :factoryName="factoryName" :is_approved="is_approved" :factory_filing="factory_filing" v-show="clickIndex == 3"></RegistrationCertificate>
        <BusinessApproval ref="businessApproval" :factory_approval="factory_approval"  v-show="clickIndex == 4"></BusinessApproval>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr marginRight18" type="primary" v-show="this.clickIndex == 4 && this.$route.query.status != '1'" @click="isput = true">保存</div>
        <div class="pageBtn finger btnSure fr marginRight18" type="primary" v-show="this.clickIndex == 4 && this.roleId == '1' && this.$route.query.status != '1'" @click="gothrogogh">一键通过</div>
        <span class="pageBtn finger btnReset fr marginRight18" @click="back">返回</span>
      </div>
      <!-- 启用模态框 -->
      <Modal v-model="isput" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
        <div class="reviewDiv">
          <p>是否确认保存？</p>
          <div>
            <span class="pageBtn finger btnSure" @click="put()">确定</span>
            <span class="pageBtn finger btnCancle" @click="isput = false">取消</span>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import BusinessLicenseInfo from './businessLicenseInfo'
import ProductLicense from './productLicense'
import RegistrationCertificate from './registrationCertificate'
import BusinessApproval from './businessApproval'
export default {
  name: 'manufacturerManage',
  components: {
    BusinessLicenseInfo,
    ProductLicense,
    RegistrationCertificate,
    BusinessApproval,
  },
  mounted() {
    // 获取选中的是生产凭证还是备案凭证
    this.roleId = window.localStorage.getItem('userRole')
    // 获取所有数据
    this.getfactoryInfor()
    // 穿工厂ID
    this.$root.eventHub.$on('sendFactoryID', res => {
      this.factoryID = res
    })
    this.$root.$on('changeFactoryName', name => {
      this.factoryName = name
    })
  },
  created() {
    console.log(this.pzInfo, '看信息')
  },
  data() {
    return {
      flag: true,
      pzInfo: {},
      roleId: null,
      // 控制保存弹窗
      isput: false,
      isLoad: false,
      navFrist: [
        {
          name: '营业执照信息',
          num: 140,
          type: 1,
          status: false,
        },
        {
          name: '生产许可证',
          num: 40,
          type: 2,
          status: false,
        },
        {
          name: '备案凭证',
          num: 40,
          type: 3,
          status: false,
        },
        {
          name: '首营审批',
          num: 40,
          type: 4,
          status: false,
        },
      ],
      //
      form: null,
      clickIndex: 1,
      factoryID: null,
      // 备案凭证
      factory_filing: '',
      factory_productlicense: '',
      factory_approval: '',
      data_maintenance: '0',
      factoryName: '',
      is_approved: null,
    }
  },
  methods: {
    pzInfo(data) {
      this.pzInfo = data
    },
    back() {
      this.$router.go(-1)
    },
    clickNav(type) {
      this.clickIndex = type
    },

    // 获取 全部 数据
    getfactoryInfor() {
      // subfactory_id传递
      let data = {
        subfactory_id: this.$route.query.subfactory_id,
      }
      // 发送请求获取所有数据
      this.$http.get(this.$api.factorySubmit, data, true).then(res => {
        // 存储显示红字段
        var near_update_field = []
        // 如果返回null 将它变为[]
        if (res.data[3].near_update_field == null) {
          near_update_field = []
        } else {
          near_update_field = res.data[3].near_update_field
        }
        // 分别传递给四个子组件
        this.$refs.businessLicenseInfo.changeList = near_update_field
        this.$refs.productLicense.changeList = near_update_field
        this.$refs.registrationCertificate.changeList = near_update_field
        this.$refs.businessApproval.changeList = near_update_field

        // 存储  data_maintenance
        this.data_maintenance = res.data[0].data_maintenance
        // 通过  this.data_maintenance   判断tab切换
        if (this.data_maintenance == '1') {
          this.navFrist[0].status = true
          this.navFrist[1].status = true
          this.navFrist[2].status = false
          this.navFrist[3].status = true
        }
        if (this.data_maintenance == '2') {
          this.navFrist[0].status = true
          this.navFrist[1].status = false
          this.navFrist[2].status = true
          this.navFrist[3].status = true
        }
        if (this.data_maintenance == '3') {
          this.navFrist[0].status = true
          this.navFrist[1].status = true
          this.navFrist[2].status = true
          this.navFrist[3].status = true
        }

        this.$refs.businessLicenseInfo.form = res.data[0]
        this.$refs.productLicense.form = res.data[1]
        this.$refs.registrationCertificate.form = res.data[2]
        this.factory_approval = res.data[3].factory_approval

        // 营业执照信息 处理时间
        this.$refs.businessLicenseInfo.form.business_end = this.$moment.unix(res.data[0].business_end).format('YYYY-MM-DD')
        this.$refs.businessLicenseInfo.form.business_start = this.$moment.unix(res.data[0].business_start).format('YYYY-MM-DD')
        this.$refs.businessLicenseInfo.form.setup_date = this.$moment.unix(res.data[0].setup_date).format('YYYY-MM-DD')
        this.$refs.businessLicenseInfo.form.registered_date = this.$moment.unix(res.data[0].registered_date).format('YYYY-MM-DD')
        if (this.$refs.businessLicenseInfo.form.business_end == '1970-01-01') {
          this.$refs.businessLicenseInfo.form.business_end = null
        }
        if (this.$refs.businessLicenseInfo.form.business_start == '1970-01-01') {
          this.$refs.businessLicenseInfo.form.business_start = null
        }
        if (this.$refs.businessLicenseInfo.form.setup_date == '1970-01-01') {
          this.$refs.businessLicenseInfo.form.setup_date = null
        }
        if (this.$refs.businessLicenseInfo.form.registered_date == '1970-01-01') {
          this.$refs.businessLicenseInfo.form.registered_date = null
        }

        // 生产许可证 处理时间
        this.$refs.productLicense.form.effective_start_date = this.$moment.unix(res.data[1].effective_start_date).format('YYYY-MM-DD')
        this.$refs.productLicense.form.effective_end_date = this.$moment.unix(res.data[1].effective_end_date).format('YYYY-MM-DD')
        this.$refs.productLicense.form.certification_date = this.$moment.unix(res.data[1].certification_date).format('YYYY-MM-DD')
        if (this.$refs.productLicense.form.effective_start_date == '1970-01-01') {
          this.$refs.productLicense.form.effective_start_date = null
        }
        if (this.$refs.productLicense.form.effective_end_date == '1970-01-01') {
          this.$refs.productLicense.form.effective_end_date = null
        }
        if (this.$refs.productLicense.form.certification_date == '1970-01-01') {
          this.$refs.productLicense.form.certification_date = null
        }

        // 备案凭证 处理时间
        this.$refs.registrationCertificate.form.filing_date = this.$moment.unix(res.data[2].filing_date).format('YYYY-MM-DD')
        if (this.$refs.registrationCertificate.form.filing_date == '1970-01-01') {
          this.$refs.registrationCertificate.form.filing_date = null
        }
        this.factoryName = res.data[0].factory_name
        this.is_approved = res.data[3].is_approved
        this.factoryManageFirstmarketingapproval()
      })
    },

    // 提交
    put() {
      if (!this.flag) return
      this.isput = false
      let postFrom1 = this.$refs.businessLicenseInfo.form
      let postFrom2 = this.$refs.productLicense.form
      let postFrom3 = this.$refs.registrationCertificate.form

      if (!postFrom1.businesslicense_key || !postFrom1.businesslicense_file_name || !postFrom1.businesslicense_url) {
        this.$Message.warning('请上传营业执照')
        return
      }
      if (!postFrom1.factory_name) {
        this.$Message.warning('请填写公司名称')
        this.isput = false
        return
      }
      if (!postFrom1.social_credit_code) {
        this.$Message.warning('请填写统一社会信用')
        this.isput = false
        return
      }
      if (!postFrom1.legal_person) {
        this.$Message.warning('请填写法人')
        this.isput = false
        return
      }
      if (!postFrom1.registered_capital) {
        this.$Message.warning('请选择注册资本')
        this.isput = false
        return
      }
      if (!postFrom1.business_range) {
        this.$Message.warning('请填写经营范围')
        this.isput = false
        return
      }
      if (!postFrom1.data_maintenance) {
        this.$Message.warning('请选择资料维护')
        this.isput = false
        return
      }
      // 如果只选择了生产许可
      if (this.data_maintenance == '1' || this.data_maintenance == '3') {
        if (!postFrom2.productionlicense_key || !postFrom2.productionlicense_file_name || !postFrom2.productionlicense_url) {
          console.log(postFrom2.productionlicense_url, '快快快')
          this.$Message.warning('请上传生产许可证')
          return
        }
        if (!postFrom2.effective_start_date) {
          this.$Message.warning('请填起始营业期限')
          this.isput = false
          return
        }
        if (!postFrom2.effective_end_date) {
          this.$Message.warning('请填截止营业期限')
          this.isput = false
          return
        }
        if (!postFrom2.license_number) {
          this.$Message.warning('请填写许可编号')
          this.isput = false
          return
        }
        if (!postFrom2.product_factory_name) {
          this.$Message.warning('请填写企业名称')
          this.isput = false
          return
        }
        if (!postFrom2.product_legal_person) {
          this.$Message.warning('请填写法人')
          this.isput = false
          return
        }
        if (!postFrom2.product_responsible_person) {
          this.$Message.warning('请填写企业负责人')
          this.isput = false
          return
        }
        if (!postFrom2.product_production_address) {
          this.$Message.warning('请填写生产地址')
          this.isput = false
          return
        }
        if (!postFrom2.product_residence) {
          this.$Message.warning('请填写住所')
          this.isput = false
          return
        }
        if (!postFrom2.product_production_range) {
          this.$Message.warning('请填写生产范围')
          this.isput = false
          return
        }
      }

      // 如果只选择了备案凭证
      if (this.data_maintenance == '2' || this.data_maintenance == '3') {
        if (!postFrom3.filing_certificate_key || !postFrom3.filing_file_name || !postFrom3.filing_certificate_url) {
          this.$Message.warning('请上传备案凭证')
          return
        }
        if (!postFrom3.filing_number) {
          this.$Message.warning('请填写备案号')
          this.isput = false
          return
        }
        if (!postFrom3.filing_factory_name) {
          this.$Message.warning('请填写企业名称')
          this.isput = false
          return
        }
        if (!postFrom3.filing_residence) {
          this.$Message.warning('请填写住所')
          this.isput = false
          return
        }
        if (!postFrom3.filing_legal_person) {
          this.$Message.warning('请填写法人')
          this.isput = false
          return
        }

        if (!postFrom3.filing_responsible_person) {
          this.$Message.warning('请填写负责人')
          this.isput = false
          return
        }
        if (!postFrom3.filing_production_address) {
          this.$Message.warning('请填写生产地址')
          this.isput = false
          return
        }
        if (!postFrom3.filing_production_range) {
          this.$Message.warning('请填写生产范围')
          this.isput = false
          return
        }
        if (!postFrom3.filing_date) {
          this.$Message.warning('请填写备案日期')
          this.isput = false
          return
        }
      }

      let data = {
        // 有就穿没有不穿
        subfactory_id: this.$route.query.subfactory_id,
        // 营业执照中的内容
        factory_id: this.$route.query.id,
        factory_name: postFrom1.factory_name,
        businesslicense_key: postFrom1.businesslicense_key,
        businesslicense_file_name: postFrom1.businesslicense_file_name,
        contract_key: postFrom1.contract_key,
        contract_file_name: postFrom1.contract_file_name,
        data_maintenance: postFrom1.data_maintenance,
        detailed_address: postFrom1.detailed_address,
        social_credit_code: postFrom1.social_credit_code, /// ///
        business_range: postFrom1.business_range,
        business_start: postFrom1.business_start,
        business_end: postFrom1.business_end,
        is_supplier: postFrom1.is_supplier,
        is_long_term: postFrom1.is_long_term,
        legal_person: postFrom1.legal_person,
        province: postFrom1.province,
        registered_capital: postFrom1.registered_capital,
        registered_date: postFrom1.registered_date,
        setup_date: postFrom1.setup_date,
        factory_type: postFrom1.factory_type, // 厂家类型 1=国内厂家 2=境外厂家 0=未知
      }
      // 如果只选择了生成凭证
      if (this.data_maintenance == '1') {
        let objs = {
          // 生产许可中的内容
          certification_date: postFrom2.certification_date,
          certification_department: postFrom2.certification_department,
          effective_end_date: postFrom2.effective_end_date,
          effective_start_date: postFrom2.effective_start_date,
          product_factory_name: postFrom2.product_factory_name,
          product_legal_person: postFrom2.product_legal_person,
          license_number: postFrom2.license_number,
          product_production_address: postFrom2.product_production_address,
          product_production_range: postFrom2.product_production_range,
          productionlicense_file_name: postFrom2.productionlicense_file_name,
          productionlicense_key: postFrom2.productionlicense_key,
          product_residence: postFrom2.product_residence,
          product_responsible_person: postFrom2.product_responsible_person,
          // 备案凭证就为null
          filing_certificate_key: null,
          filing_certificate_url: null,
          filing_date: null,
          filing_file_name: null,
          filing_number: null,
          filing_factory_name: null,
          filing_production_address: null,
          filing_residence: null,
          filing_legal_person: null,
          filing_responsible_person: null,
          filing_production_range: null,
          filing_department: null,
        }
        data = Object.assign({}, data, objs)
      } else if (this.data_maintenance == '2') {
        let info = {
          // 生产许可中的内容
          certification_date: null,
          certification_department: null,
          effective_end_date: null,
          effective_start_date: null,
          product_factory_name: null,
          product_legal_person: null,
          license_number: null,
          product_production_address: null,
          product_production_range: null,
          productionlicense_file_name: null,
          productionlicense_key: null,
          product_residence: null,
          product_responsible_person: null,
          // 备案凭证
          filing_certificate_key: postFrom3.filing_certificate_key,
          filing_certificate_url: postFrom3.filing_certificate_url,
          filing_date: postFrom3.filing_date,
          filing_file_name: postFrom3.filing_file_name,
          filing_number: postFrom3.filing_number,
          filing_factory_name: postFrom3.filing_factory_name,
          filing_production_address: postFrom3.filing_production_address,
          filing_residence: postFrom3.filing_residence,
          filing_legal_person: postFrom3.filing_legal_person,
          filing_responsible_person: postFrom3.filing_responsible_person,
          filing_production_range: postFrom3.filing_production_range,
          filing_department: postFrom3.filing_department,
        }
        data = Object.assign({}, data, info)
      } else if (this.data_maintenance == '3') {
        let info3 = {
          // 生成凭证
          certification_date: postFrom2.certification_date,
          certification_department: postFrom2.certification_department,
          effective_end_date: postFrom2.effective_end_date,
          effective_start_date: postFrom2.effective_start_date,
          product_factory_name: postFrom2.product_factory_name,
          product_legal_person: postFrom2.product_legal_person,
          license_number: postFrom2.license_number,
          product_production_address: postFrom2.product_production_address,
          product_production_range: postFrom2.product_production_range,
          productionlicense_file_name: postFrom2.productionlicense_file_name,
          productionlicense_key: postFrom2.productionlicense_key,
          product_residence: postFrom2.product_residence,
          product_responsible_person: postFrom2.product_responsible_person,
          // 备案凭证
          filing_certificate_key: postFrom3.filing_certificate_key,
          filing_certificate_url: postFrom3.filing_certificate_url,
          filing_date: postFrom3.filing_date,
          filing_file_name: postFrom3.filing_file_name,
          filing_number: postFrom3.filing_number,
          filing_factory_name: postFrom3.filing_factory_name,
          filing_production_address: postFrom3.filing_production_address,
          filing_residence: postFrom3.filing_residence,
          filing_legal_person: postFrom3.filing_legal_person,
          filing_responsible_person: postFrom3.filing_responsible_person,
          filing_production_range: postFrom3.filing_production_range,
          filing_department: postFrom3.filing_department,
        }
        data = Object.assign({}, data, info3)
      }
      // 提交调用接口
      this.$http.post(this.$api.factorySubmit, data, true).then(res => {
        let postFrom4 = this.$refs.businessApproval.form
        let data1 = {
          factory_id: this.$route.query.subfactory_id, // 工厂ID
          factory_approval: this.factory_approval,
          purchasing_opinion: postFrom4.purchasing_opinion, // 财务部审核意见
          purchasing_person: postFrom4.purchasing_person, // 财务部负责人签名
          purchasingn_state: postFrom4.purchasingn_state, //	财务部状态0=驳回 1=通过
          purchasing_date: postFrom4.purchasing_date, // 财务部审核日期
          quality_opinion: postFrom4.quality_opinion, //	质量部审核意见
          qualityn_person: postFrom4.qualityn_person, //	质量部负责人签名
          quality_state: postFrom4.quality_state, // 质量部盛和状态0=驳回 1=通过
          quality_date: postFrom4.quality_date, //	质量部审核日期
          finance_opinion: postFrom4.finance_opinion, // 财务部审核意见
          finance_person: postFrom4.finance_person, //	财务部负责人签字
          finance_state: postFrom4.finance_state, // 财务部审核状态0=驳回 1=通过
          finance_date: postFrom4.finance_date, // 财务部审核日期
          admin_opinion: postFrom4.admin_opinion, //	管理者审核意见
          admin_person: postFrom4.admin_person, // 管理部负责人签字
          admin_state: postFrom4.admin_state, // 管理部审核状态0=驳回 1=通过
          admin_date: postFrom4.admin_date, //	管理部审核日期
        }

        // 如果是管理员  或者总经理  就要填写所有的
        if (this.roleId == '1' || this.roleId == '2') {
          if (!data1.purchasing_opinion) {
            this.$Message.warning('请填写采购部意见')
            return
          }
          if (!data1.purchasing_person) {
            this.$Message.warning('请填写采购部负责人签字')
            return
          }
          if (!data1.purchasingn_state) {
            this.$Message.warning('请选择采购部同意或驳回选项')
            return
          }
          if (!data1.purchasing_date) {
            this.$Message.warning('请填写采购部签字日期')
            return
          }
          if (!data1.quality_opinion) {
            this.$Message.warning('请填写质量管理部意见')
            return
          }
          if (!data1.qualityn_person) {
            this.$Message.warning('请填写质量管理部门负责人签字')
            return
          }
          if (!data1.quality_state) {
            this.$Message.warning('请选择质量管理部同意或驳回选项')
            return
          }
          if (!data1.quality_date) {
            this.$Message.warning('请填写质量管理部门签字日期')
            return
          }
          if (!data1.finance_opinion) {
            this.$Message.warning('请填写财务部意见')
            return
          }
          if (!data1.finance_person) {
            this.$Message.warning('请填写财务部负责人签字')
            return
          }
          if (!data1.finance_state) {
            this.$Message.warning('请选择财务部同意或驳回选项')
            return
          }
          if (!data1.finance_date) {
            this.$Message.warning('请填写财务部签字日期')
            return
          }
          if (!data1.admin_opinion) {
            this.$Message.warning('请填写总经理意见')
            return
          }
          if (!data1.admin_person) {
            this.$Message.warning('请填写总经理责人签字')
            return
          }
          if (!data1.admin_state) {
            this.$Message.warning('请选择总经理同意或驳回选项')
            return
          }
          if (!data1.admin_date) {
            this.$Message.warning('请填写总经理签字日期')
            return
          }
        }

        // 如果是采购部
        if (this.roleId == '3' || this.roleId == '4') {
          if (!data1.purchasing_person) {
            this.$Message.warning('请填写采购部审核意见')
            return
          }
          if (!data1.purchasing_person) {
            this.$Message.warning('请填写采购部负责人签字')
            return
          }
          if (!data1.purchasingn_state) {
            this.$Message.warning('请选择采购部同意或驳回选项')
            return
          }
          if (!data1.purchasing_date) {
            this.$Message.warning('请填写采购部签字日期')
            return
          }
        }

        // 如果是质量管理部门
        if (this.roleId == '8') {
          if (!data1.quality_opinion) {
            this.$Message.warning('请填写质量管理部意见')
            return
          }
          if (!data1.qualityn_person) {
            this.$Message.warning('请填写质量管理部门负责人签字')
            return
          }
          if (!data1.quality_state) {
            this.$Message.warning('请选择质量管理部同意或驳回选项')
            return
          }
          if (!data1.quality_date) {
            this.$Message.warning('请填写质量管理部门签字日期')
            return
          }
        }

        // 如果是财务部
        if (this.roleId == '10' || this.roleId == '11') {
          if (!data1.finance_opinion) {
            this.$Message.warning('请填写财务部意见')
            return
          }
          if (!data1.finance_person) {
            this.$Message.warning('请填写财务部负责人签字')
            return
          }
          if (!data1.finance_state) {
            this.$Message.warning('请选择财务部同意或驳回选项')
            return
          }
          if (!data1.finance_date) {
            this.$Message.warning('请填写财务部签字日期')
            return
          }
        }
        this.flag = false
        this.$http.post(this.$api.factoryManageFirstmarketingapproval, data1, true).then(res => {
          this.flag = true
          this.$router.push('/supplierManage')
          this.$Message.success('保存成功')
        })
      })
      // 更新一级页面的数据
      sessionStorage.setItem('updataCache', '0')
    },

    // 获取首印审批
    factoryManageFirstmarketingapproval() {
      let data = {
        factory_approval: this.factory_approval,
      }
      if (data.factory_approval != null) {
        this.$http.get(this.$api.factoryManageFirstmarketingapproval, data, true).then(res => {
          // 首印审批时间处理
          this.$refs.businessApproval.form = res.data
          this.$refs.businessApproval.form.purchasing_date = this.$moment.unix(this.$refs.businessApproval.form.purchasing_date).format('YYYY-MM-DD HH:mm:ss')
          this.$refs.businessApproval.form.quality_date = this.$moment.unix(this.$refs.businessApproval.form.quality_date).format('YYYY-MM-DD HH:mm:ss')
          this.$refs.businessApproval.form.finance_date = this.$moment.unix(this.$refs.businessApproval.form.finance_date).format('YYYY-MM-DD HH:mm:ss')
          this.$refs.businessApproval.form.admin_date = this.$moment.unix(this.$refs.businessApproval.form.admin_date).format('YYYY-MM-DD HH:mm:ss')
          if (this.$refs.businessApproval.form.purchasing_date == '1970-01-01 08:00:00') {
            this.$refs.businessApproval.form.purchasing_date = null
          }
          if (this.$refs.businessApproval.form.quality_date == '1970-01-01 08:00:00') {
            this.$refs.businessApproval.form.quality_date = null
          }
          if (this.$refs.businessApproval.form.finance_date == '1970-01-01 08:00:00') {
            this.$refs.businessApproval.form.finance_date = null
          }
          if (this.$refs.businessApproval.form.admin_date == '1970-01-01 08:00:00') {
            this.$refs.businessApproval.form.admin_date = null
          }
        })
      }
    },

    // 首印审批 一键通过
    gothrogogh() {
      if (!this.flag) return
      this.flag = false
      this.$http.get(this.$api.factoryOnePass, false).then(res => {
        this.flag = true
        this.$refs.businessApproval.form = res.data
        this.$refs.businessApproval.form.purchasing_date = this.$moment.unix(this.$refs.businessApproval.form.purchasing_date).format('YYYY-MM-DD HH:mm:ss')
        this.$refs.businessApproval.form.quality_date = this.$moment.unix(this.$refs.businessApproval.form.quality_date).format('YYYY-MM-DD HH:mm:ss')
        this.$refs.businessApproval.form.finance_date = this.$moment.unix(this.$refs.businessApproval.form.finance_date).format('YYYY-MM-DD HH:mm:ss')
        this.$refs.businessApproval.form.admin_date = this.$moment.unix(this.$refs.businessApproval.form.admin_date).format('YYYY-MM-DD HH:mm:ss')
        this.$refs.businessApproval.form.purchasingn_state = this.$refs.businessApproval.form.purchasingn_state + ''
        this.$refs.businessApproval.form.admin_state = this.$refs.businessApproval.form.admin_state + ''
        this.$refs.businessApproval.form.finance_state = this.$refs.businessApproval.form.finance_state + ''
        this.$refs.businessApproval.form.quality_state = this.$refs.businessApproval.form.quality_state + ''
        this.$refs.businessApproval.form.factory_id = this.$refs.businessApproval.form.factory_id
        this.put()
      })
      // 更新一级页面的数据
      sessionStorage.setItem('updataCache', '0')
    },
  },
}
</script>

<style scoped lang="less">
.marginRight18 {
  margin-right: 18px;
}
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .btmDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0px 25px 30px 25px;
    .formDiv {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 30px;
    }
    .saveDiv {
      height: 36px;
    }
  }
  .tabelDiv {
    flex: 1;
    border-radius: 0 8px 8px 8px;
    padding: 20px 0px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
</style>
